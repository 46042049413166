<template>
  <div class="home">
    <h1>Bienvenid@</h1>
  </div>
</template>

<script>

export default {

}
</script>
