import {createStore} from 'vuex'

export default createStore({
    state: {
        paises: [],
        paisesFiltrados: []
    },
    mutations: {
        setPaises(state, payload) {
            state.paises = payload;
        },
        setPaisesFiltrados(state, payload) {
            state.paisesFiltrados = payload
        }
    },
    actions: {
        async getPaises({commit}) {
            try {
                const res = await fetch("https://archivos.tecnolideres.org/api/countries/api.json");
                const data = await res.json();

                commit("setPaises", data);
            } catch (err) {
                console.error(err);
            }
        },
        filtrarRegion({commit, state}, region) {
            const filtrado = state.paises.filter(pais => {
                return pais.region.includes(region);
            });

            commit("setPaisesFiltrados", filtrado);
        },
        filtrarPorNombre({commit, state}, nombre) {
            const normalizarTexto = (texto) => {
                texto = texto.toLowerCase();
                texto = texto.replace("á", "a");
                texto = texto.replace("é", "e");
                texto = texto.replace("í", "i");
                texto = texto.replace("ó", "o");
                texto = texto.replace("ú", "u");
                texto = texto.replace("ö", "o");
                texto = texto.replace("ü", "u");
                texto = texto.replace("ñ", "n");

                return texto;
            };
            const filtrado = state.paises.filter(pais => {
                return normalizarTexto(pais.translations.spa.common).includes(normalizarTexto(nombre));
            });

            commit("setPaisesFiltrados", filtrado);
        }
    },
    getters: {
        topPaisesPoblacion(state) {
            return state.paisesFiltrados.sort((a, b) => {
                return a.area < b.area ? 1 : -1;
            });
        }
    },
    modules: {}
})
