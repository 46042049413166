<template>
  <nav>
    <router-link to="/">Home</router-link> |
<!--    <router-link to="/contador-opciones">ContaOpciones</router-link> |-->
<!--    <router-link to="/contador-composition">ContaComposition</router-link> |-->
<!--    <router-link to="/contador-hooks">ContaHooks</router-link> |-->
    <router-link to="/api-paisesdinam">ConsultaApiPaisesDinam</router-link> |
    <router-link to="/api-paises">ConsultaApiPaises</router-link>
  </nav>
  <router-view/>
</template>
<script>
import {onMounted} from "vue";

export default {
  setup() {
    onMounted(async() => {
      /*const ancho = $(window).width();
    const largo = $(window).height();
    $("body").css('background-image', `url("https://random.imagecdn.app/v1/image?width=${ancho}&height=${largo}&format=image")`);*/
      const ancho = screen.width;
      const largo = screen.height;

      document.body.style.backgroundImage = `url("https://random.imagecdn.app/v1/image?width=${ancho}&height=${largo}&format=image")`;
    });
  }
}
</script>
<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin: 30px;
  background-color: #ffffff;
  background: radial-gradient(circle at 100% 100%, #ffffff 0, #ffffff 3px, transparent 3px) 0% 0%/8px 8px no-repeat,
  radial-gradient(circle at 0 100%, #ffffff 0, #ffffff 3px, transparent 3px) 100% 0%/8px 8px no-repeat,
  radial-gradient(circle at 100% 0, #ffffff 0, #ffffff 3px, transparent 3px) 0% 100%/8px 8px no-repeat,
  radial-gradient(circle at 0 0, #ffffff 0, #ffffff 3px, transparent 3px) 100% 100%/8px 8px no-repeat,
  linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 10px) calc(100% - 16px) no-repeat,
  linear-gradient(#ffffff, #ffffff) 50% 50%/calc(100% - 16px) calc(100% - 10px) no-repeat,
  linear-gradient(0deg, transparent 0%, #48abe0 100%);
  border-radius: 8px;
  padding: 9px;
  box-sizing: border-box;
  opacity: 0.90;
}

body {
  background-image: url('assets/logo.png');
  background-size: auto;
  background-repeat: repeat;
  opacity: 1;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}
</style>
